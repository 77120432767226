import aboutUsIBackground from "./aboutUs/background.svg";
import aboutUsImage from "./aboutUs/Image.svg";
import CommentsBackground from "./Comments/background.svg";
import Quoet from "./Comments/Quot.svg";
import user1 from "./Comments/user1.svg";
import user2 from "./Comments/user2.svg";
import user3 from "./Comments/user3.svg";
import user4 from "./Comments/user4.svg";
import ElectricIcon from "./features/Electric.svg";
import HeartIcon from "./features/HeartIcon.svg";
import MusicIcon from "./features/MusicICon.svg";
import FooterAppsStore from "./Footer/AppsStore.svg";
import FooterBackground from "./Footer/background.svg";
import FooterGooglePlay from "./Footer/GooglePlay.svg";
import FooterPhone from "./Footer/phone.svg";
import AppStoreBtn from "./Header/AppStoreBtn.svg";
import Background from "./Header/background.svg";
import GoogleStoreBtn from "./Header/GoogleStoreBtn.svg";
import HeaderInfoBackground from "./Header/HeaderInfoBackground.svg";
import Home from "./Header/homeIcon.svg";
import Logo from "./Header/logo.svg";
import MobileFrame from "./Header/MobileFrame.svg";
import MobilePhoto from "./Header/mobilePhoto.svg";
import HowItWorksImage1 from "./HowItWorks/Image1.svg";
import HowItWorksImage2 from "./HowItWorks/Image2.svg";
import HowItWorksMobile1 from "./HowItWorks/MobileFrame1.svg";
import HowItWorksMobile2 from "./HowItWorks/MobileFrame2.svg";
import Anoosh from "./Partners/Anoosh-01.svg";
import PartnersBackground from "./Partners/background.svg";
import Bahrain from "./Partners/Bahrain-01.svg";
import Sharjah from "./Partners/Bank-of-Sharjah-01.svg";
import CNN from "./Partners/CNN-01.svg";
import FitnessTime from "./Partners/FitnessTime-01.svg";
import GeneralAuthorityCivilAviation from "./Partners/General-Authority-of-civil-Aviation-01.svg";
import HomeCenter from "./Partners/Home-Center-1-01.svg";
import SportForAll from "./Partners/Sport-For-All-01.svg";
import Garage from "./Partners/The-Garage-01.svg";
import Rowaad from "./rowaad.svg";

export const Icons = {
  header: {
    Home,
    Logo,
    Background,
    MobileFrame,
    MobilePhoto,
    HeaderInfoBackground,
    AppStoreBtn,
    GoogleStoreBtn,
  },
  aboutUs: {
    Image: aboutUsImage,
    Background: aboutUsIBackground,
  },
  features: {
    MusicIcon,
    ElectricIcon,
    HeartIcon,
  },
  Partners: {
    Background: PartnersBackground,
    Anoosh,
    Bahrain,
    Sharjah,
    CNN,
    FitnessTime,
    GeneralAuthorityCivilAviation,
    HomeCenter,
    SportForAll,
    Garage,
  },
  Comments: {
    Background: CommentsBackground,
    Quoet,
    user1,
    user2,
    user3,
    user4,
  },
  Footer: {
    Background: FooterBackground,
    Phone: FooterPhone,
    AppsStore: FooterAppsStore,
    GooglePlay: FooterGooglePlay,
  },
  HowItWorks: {
    HowItWorksImage1,
    HowItWorksImage2,
    HowItWorksMobile1,
    HowItWorksMobile2,
  },
  ArrowLeftWhite: null,
  ArrowRightWhite: null,
  ArrowRight: null,
  ArrowLeft: "null",
  Rowaad,
};
