import endpoint from "shared/endpoint";
import user from "user";
import { contactAtom } from "../../atoms/contact-atom";
import { privacyAtom } from "../../atoms/privacyAtom";

export function getGuestToken() {
  return new Promise(resolve => {
    if (user.getAccessToken()) {
      return resolve({});
    }

    endpoint.post("/login/guests").then(resolve);
  });
}

export function getSettings() {
  return new Promise(resolve => {
    endpoint.get("/contact-us").then((response: any) => {
      contactAtom.update(response.data.records);

      return resolve(response);
    });
  });
}

export function getPrivacyPolicy() {
  return new Promise(resolve => {
    endpoint.get("/pages/privacy-policy").then((response: any) => {
      privacyAtom.update(response.data.record);

      return resolve(response);
    });
  });
}
