import endpoint from "shared/endpoint";
import { landPageAtom } from "./../../atoms/landPageAtom";

export function getLandPage() {
  return new Promise(resolve => {
    endpoint.get("/landingPages").then(response => {
      landPageAtom.update(response?.data);

      resolve(response);
    });
  });
}
