import { ErrorHandlerProps } from "./types";

export default function ErrorHandler({ error }: ErrorHandlerProps) {
  return (
    <div>
      {error?.message ||
        error?.response?.data?.data?.errors?.[0]?.value ||
        error?.response?.message}
    </div>
  );
}
