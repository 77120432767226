import { extend, groupedTranslations } from "@mongez/localization";
import { arTranslation, enTranslation } from "@mongez/validator";

extend("en", enTranslation);
extend("ar", arTranslation);

// Add only common localization
groupedTranslations({
  loading: {
    en: "Loading...",
    ar: "جار التحميل...",
  },
  youAreOffline: {
    en: "You are offline now!",
    ar: "لقد فقدت الإتصال بالإنترنت!",
  },
  somethingWentWrong: {
    en: "Something Went wrong, please try again later.",
    ar: "حدث خطأ ما, من فضلك حاول مرة أخرى في وقت لاحق.",
  },
  errorHeading: {
    en: "Error!",
    ar: "خطأ!",
  },
  accessDenied: {
    en: "Access Denied!",
    ar: "غير مصرح لك بدخول هذه الصفحة!",
  },
  notFoundPage: {
    en: "Not Found Page",
    ar: "الصفحة المطلوبة غير موجودة",
  },
  home: {
    ar: "الرئيسية",
    en: "Home",
  },
  aboutUs: {
    ar: "من نحن",
    en: "About Us",
  },
  howItWorks: {
    ar: "كيف يعمل تطبيق",
    en: "How does app work",
  },
  ourServices: {
    ar: "خدماتنا",
    en: "Our services",
  },
  downloadApp: {
    ar: "حمل التطبيق",
    en: "Download app",
  },
  privacyPolicy: {
    ar: "سياسة الخصوصيه",
    en: "privacyPolicy",
  },
  contactUs: {
    ar: "اتصل بنا",
    en: "contact Us",
  },
  name: {
    ar: "الاسم",
    en: "name",
  },
  email: {
    ar: "البريد الالكترونى",
    en: "email",
  },
  title: {
    ar: "العنوان",
    en: "title",
  },
  message: {
    ar: "الرسالة",
    en: "message",
  },
  contactInfo: {
    ar: "بيانات التواصل",
    en: "contact Info",
  },
  address: {
    ar: "العنوان",
    en: "address",
  },
  phone: {
    ar: "رقم الهاتف",
    en: "phone",
  },
  send: {
    ar: "ارسال",
    en: "send",
  },
});
