import createCache from "@emotion/cache";
import { MantineProvider } from "@mantine/core";
import { setFavIcon, setTitle } from "@mongez/dom";
import { current } from "@mongez/react";
import { pipeline, preload } from "@mongez/react-utils";
import {
  getGuestToken,
  getPrivacyPolicy,
  getSettings,
} from "app/global/services/GlobalServices";
import { Icons } from "shared/assets/svgs";
import rtlPlugin from "stylis-plugin-rtl";
import { getLandPage } from "../../../home/services/home-service";
import { MainContainer } from "../../components/Containers";

const cacheRTL = createCache({
  key: "rtl",
  stylisPlugins: [rtlPlugin],
});

function _Root({ children }: any) {
  setFavIcon(Icons.header.Logo);
  setTitle("صهوة العقارية");

  return (
    <MainContainer>
      <MantineProvider
        theme={{
          fontFamily:
            current("localeCode") === "ar" ? "GraphikArabic" : "GraphikArabic",
          dir: current("localeCode") === "ar" ? "rtl" : "ltr",
          loader: "bars",
        }}
        emotionCache={current("localeCode") === "ar" ? cacheRTL : undefined}
        withGlobalStyles
        withNormalizeCSS>
        {children}
      </MantineProvider>
    </MainContainer>
  );
}

const Root = preload(
  _Root,
  pipeline([getGuestToken, getSettings, getLandPage, getPrivacyPolicy]),
);

export default Root;
