import {
  EncryptedLocalStorageDriver,
  PlainLocalStorageDriver,
} from "@mongez/cache";
import { setAppConfigurations } from "@mongez/react";
import { AES } from "crypto-js";
import { appCode, __DEV__ } from "./flags";

setAppConfigurations({
  encryption: {
    key: appCode,
    driver: AES,
  },
  cache: {
    prefix: appCode,
    driver: __DEV__
      ? new PlainLocalStorageDriver()
      : new EncryptedLocalStorageDriver(),
  },
});
