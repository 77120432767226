import { Skeleton } from "@mantine/core";
import { MainContainer } from "../../Containers";
import Loader from "../Loader";
import ErrorHandler from "./../ErrorHandler";
import { LoadingErrorHandlerProps } from "./types";

export default function LoadingErrorHandler({
  error = null,
  isLoading = undefined,
  innerSection = false,
}: LoadingErrorHandlerProps & {
  innerSection?: boolean;
  errorCentered?: boolean;
}) {
  if (isLoading !== undefined) {
    return innerSection ? (
      <MainContainer>
        <Skeleton height={50} circle mb="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </MainContainer>
    ) : (
      <>
        <Loader isLoading={isLoading} />
      </>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return null;
}
