import { Loader as MantineLoader } from "@mantine/core";
import { Flex } from "../../styles";
import { LoaderProps } from "./types";

export default function Loader({ isLoading = true }: LoaderProps) {
  if (!isLoading) return null;

  return (
    <Flex justifyContent="center" alignItems="center">
      <MantineLoader size="xl" variant="bars" />
    </Flex>
  );
}
